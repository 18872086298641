<template>
  <div>
    <!-- 头部 -->
    <Header></Header>
    <!-- 大图 -->
    <div class="dt">
      <div class="dt_text">
        <div class="text1">小程序解决方案</div>
        <div class="text2">聚流量 · 高转化 · 降成本 · 提销量</div>
      </div>
    </div>
    <div>
      <div class="hytitle">行业模板</div>
      <div class="hysubtitle">多套模板提供选择</div>
    </div>
    <div class="tbs">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="最新模板" name="first"></el-tab-pane>
        <el-tab-pane label="热门模板" name="second"></el-tab-pane>
      </el-tabs>
    </div>
    <!-- 1 -->
    <div class="hymb clearfix" v-if="activeName == 'first'">
      <div class="hymbmk" v-for="item of fk" :key="item.index">
        <div><el-image class="hymb-tp" :src="item.url"></el-image></div>
        <div><el-image class="hymb-qr" :src="item.qrurl"></el-image></div>
      </div>
    </div>
    <!-- 2 -->
    <div class="hymb clearfix" v-if="activeName == 'second'">
      <div class="hymbmk" v-for="item of mb" :key="item.index">
        <div><el-image class="hymb-tp" :src="item.url"></el-image></div>
        <div><el-image class="hymb-qr" :src="item.qrurl"></el-image></div>
      </div>
    </div>

    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "wx",
  data() {
    return {
      activeName: "first",
      mb: [
        {
          url: "http://14.116.154.181:8890/bisi_website/xcx/temp1.png",
          qrurl: "http://14.116.154.181:8890/bisi_website/xcx/temp1-1.jpg",
        },
        {
          url: "http://14.116.154.181:8890/bisi_website/xcx/temp2.png",
          qrurl: "http://14.116.154.181:8890/bisi_website/xcx/temp2-1.jpg",
        },
        {
          url: "http://14.116.154.181:8890/bisi_website/xcx/temp3.png",
          qrurl: "http://14.116.154.181:8890/bisi_website/xcx/temp3-1.jpg",
        },
        {
          url: "http://14.116.154.181:8890/bisi_website/xcx/dc.png",
          qrurl: "http://14.116.154.181:8890/bisi_website/xcx/dc-1.jpg",
        },
      ],
      fk: [
        {
          url: "http://14.116.154.181:8890/bisi_website/yyfk/yyfk1.png",
          qrurl: "http://14.116.154.181:8890/bisi_website/yyfk/yyfk1-1.png",
        },
        {
          url: "http://14.116.154.181:8890/bisi_website/yyfk/yyfk2.png",
          qrurl: "http://14.116.154.181:8890/bisi_website/yyfk/yyfk2-1.png",
        },
        {
          url: "http://14.116.154.181:8890/bisi_website/yyfk/yyfk3.png",
          qrurl: "http://14.116.154.181:8890/bisi_website/yyfk/yyfk3-1.png",
        },
        {
          url: "http://14.116.154.181:8890/bisi_website/yyfk/yyfk4.png",
          qrurl: "http://14.116.154.181:8890/bisi_website/yyfk/yyfk4-1.png",
        },
        {
          url: "http://14.116.154.181:8890/bisi_website/yyfk/yyfk5.png",
          qrurl: "http://14.116.154.181:8890/bisi_website/yyfk/yyfk5-1.png",
        },
        {
          url: "http://14.116.154.181:8890/bisi_website/yyfk/yyfk6.png",
          qrurl: "http://14.116.154.181:8890/bisi_website/yyfk/yyfk6-1.png",
        },
        {
          url: "http://14.116.154.181:8890/bisi_website/yyfk/yyfk7.png",
          qrurl: "http://14.116.154.181:8890/bisi_website/yyfk/yyfk7-1.png",
        },
        {
          url: "http://14.116.154.181:8890/bisi_website/yyfk/yyfk8.png",
          qrurl: "http://14.116.154.181:8890/bisi_website/yyfk/yyfk8-1.png",
        },
        {
          url: "http://14.116.154.181:8890/bisi_website/yyfk/yyfk9.png",
          qrurl: "http://14.116.154.181:8890/bisi_website/yyfk/yyfk9-1.png",
        },
      ],
    };
  },
  components: { Header, Footer },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style scoped>
/* 大图 */
.dt {
  height: 545px;
  width: 100%;
  background-image: url("http://14.116.154.181:8890/bisi_website/wx1.jpg");
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: top center;
}
.dt_text {
  margin: 0 auto;
  width: 1100px;
  height: 545px;
  text-align: left;
}
.text1 {
  padding: 163px 0px 30px 0px;
  font-size: 55px;
  color: #ffffff;
}
.text2 {
  font-weight: 700;
  padding-bottom: 20px;
  font-size: 30px;
  color: #ffffff;
}
.hytitle {
  margin-top: 30px;
  font-size: 36px;
  color: rgb(51, 51, 51);
  line-height: 60px;
}
.hysubtitle {
  color: rgb(51, 51, 51);
}
.hymb {
  width: 1200px;
  margin: 0 auto;
}
.hymbmk {
  float: left;
  margin: 30px 0px;
}
.hymb-tp {
  width: 340px;
  height: 600px;
  margin: 15px 30px;
}
.hymb-qr {
  width: 150px;
  height: 150px;
}

.tbs {
  width: 100%;
}
.el-tabs {
  margin: 20px auto 5px;

  width: 160px;
}
el-tabs__nav-wrap .is-top {
  background-color: #ffffff !important;
}
</style>